import { InboxOutlined } from "@ant-design/icons";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Button, DatePicker, Form, Input, Select, Spin, message } from "antd";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";
const { Option } = Select;

export const DenunciaForm = () => {
  const [denuncia, setDenuncia] = useState({
    Anonima: true,
    Nombre: "",
    Telefono: "",
    Email: "",
    fecha_echos: null,
    Lugar_echos: "",
    Descripcion: "",
    Id_denuncia_im: [],
    Id_denuncia_te: [],
    Anexos: null,
  });
  const [loading, setLoading] = useState(false);
  const [captchaResult, setCaptchaResult] = useState();

  const handleAnonimaChange = (value) => {
    setDenuncia((prevState) => ({
      ...prevState,
      Anonima: value === "SI",
      Nombre: "",
      Telefono: "",
      Email: "",
    }));
  };

  const [anexoFile, setAnexoFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [Id_testigo, setTestigo] = useState([]);
  const [Id_implicado, setImplicado] = useState([]);

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    setAnexoFile(file); // Guarda el archivo en el estado
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });
  const handleAddTestigos = () => {
    setTestigo([...Id_testigo, ""]);
  };

  const handleAddImplicados = () => {
    setImplicado([...Id_implicado, ""]);
  };

  const handleDeleteTestigos = (index) => {
    setTestigo(Id_testigo.filter((_, i) => i !== index));
  };

  const handleDeleteImplicados = (index) => {
    setImplicado(Id_implicado.filter((_, i) => i !== index));
  };

  const handleTestigosChange = (index, value) => {
    setTestigo([
      ...Id_testigo.slice(0, index),
      { Nom_testigo: value },
      ...Id_testigo.slice(index + 1),
    ]);
  };

  const handleImplicadosChange = (index, value) => {
    setImplicado([
      ...Id_implicado.slice(0, index),
      { Nom_implicado: value },
      ...Id_implicado.slice(index + 1),
    ]);
  };
  const handleRecaptcha = (value) => {
    fetch("https://lineaeticaback.grupo-syz.com/recaptcha/", {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true); // Activar el loader
      // Creamos un objeto FormData para enviar los archivos adjuntos
      const formData = new FormData();
      formData.append("Anonima", values.Anonima === "SI"); // Convertir a booleano
      formData.append("Nombre", values.Nombre);
      formData.append("Telefono", values.Telefono);
      formData.append("Email", values.Email);
      formData.append("fecha_echos", values.fecha_echos.format("YYYY-MM-DD")); // Formateamos la fecha a un formato compatible
      formData.append("Lugar_echos", values.Lugar_echos);
      formData.append("Descripcion", values.Descripcion);
      formData.append("Id_denuncia_im", JSON.stringify(Id_implicado));
      formData.append("Id_denuncia_te", JSON.stringify(Id_testigo));

      if (anexoFile !== null) {
        formData.append("Anexos", anexoFile); // Agrega el archivo adjunto usando la variable 'anexoFile'
      } else {
        formData.append("Anexos", ""); // Agrega el archivo adjunto usando la variable 'anexoFile'
      }

      // Realizamos la petición POST a la API utilizando fetch
      const response = await fetch("https://lineaeticaback.grupo-syz.com/api/denuncia/", {
        method: "POST",
        body: formData,
      });

      // Verificamos el estado de la respuesta
      if (response.ok) {
        // Mostramos un mensaje de éxito y limpiamos el formulario
        message.success("Denuncia enviada con éxito");
        setDenuncia({
          Anonima: true,
          Nombre: "",
          Telefono: "",
          Email: "",
          fecha_echos: null,
          Lugar_echos: "",
          Descripcion: "",
          Id_denuncia_im: [],
          Id_denuncia_te: [],
          Anexos: null,
        });
      } else {
        // Mostrar mensaje de error
        message.error(
          "Error al enviar la denuncia. Por favor, inténtalo nuevamente."
        );
      }
    } catch (error) {
      // Mostrar mensaje de error
      message.error(
        "Error al enviar la denuncia. Por favor, inténtalo nuevamente."
      );
    } finally {
      setLoading(false); // Desactivar el loader
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <p>
            Para utilizar este mecanismo de reporte por favor tener en cuenta
            que:
            <ul>
              <li>
                No es un medio para quejas y reclamos, sino de reporte por
                conductas que van contra la Transparencia e Integridad de la
                Compañía.
              </li>
              <li>
                No es para sugerencias, ni atención de temas laborales, ni
                personales.
              </li>
              <li>
                Debe ser utilizada con responsabilidad, los hechos reportados
                deben ser reales y verificables.
              </li>
            </ul>
          </p>

          <h2 style={{ textAlign: "center" }}>Reporte de Denuncia</h2>
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Denuncia Anónima"
              name="Anonima"
              initialValue={denuncia.Anonima ? "SI" : "NO"}
              rules={[
                { required: true, message: "Por favor seleccione una opción" },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Select onChange={handleAnonimaChange}>
                <Option value="SI">SI</Option>
                <Option value="NO">NO</Option>
              </Select>
            </Form.Item>

            {!denuncia.Anonima && (
              <>
                <Form.Item
                  label="Nombre y Apellidos"
                  name="Nombre"
                  initialValue={denuncia.Nombre}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su Nombre y apellidos",
                    },
                  ]}
                  style={{ marginBottom: "10px" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Teléfono"
                  name="Telefono"
                  initialValue={denuncia.Telefono}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su teléfono",
                    },
                  ]}
                  style={{ marginBottom: "10px" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Correo electrónico"
                  name="Email"
                  initialValue={denuncia.Email}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su correo electrónico",
                    },
                    {
                      type: "Email",
                      message: "Por favor ingrese un correo electrónico válido",
                    },
                  ]}
                  style={{ marginBottom: "10px" }}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Fecha de los hechos"
              name="fecha_echos"
              initialValue={denuncia.fecha_echos}
              rules={[
                { required: true, message: "Por favor seleccione una fecha" },
              ]}
              labelCol={{ span: 24 }} // Ancho completo para la etiqueta
              wrapperCol={{ span: 24 }} // Ancho completo para el campo
              style={{ marginBottom: "10px" }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Lugar de los hechos"
              name="Lugar_echos"
              initialValue={denuncia.Lugar_echos}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el lugar de los hechos",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Descripción de los hechos"
              name="Descripcion"
              initialValue={denuncia.Descripcion}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese la descripción de los hechos",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input.TextArea />
            </Form.Item>

            <div>
              <Typography variant="h6" style={{ marginBottom: "10px" }}>
                Personas Implicadas
              </Typography>
              <TableContainer
                component={Paper}
                style={{ marginBottom: "20px" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#ccc", // Cambia este valor al tono de gris deseado
                            color: "white", // Cambia el color del texto si es necesario
                            borderColor: "#ccc", // Cambia el color del borde si es necesario
                          }}
                          size="small"
                          onClick={handleAddImplicados}
                        >
                          +
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Id_implicado.map((bahia, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Form.Item
                            label="Nombre y Apellidos"
                            initialValue={bahia.Nom_implicado}
                            onChange={(e) =>
                              handleImplicadosChange(index, e.target.value)
                            }
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingrese Nombre y apellidos",
                              },
                            ]}
                            style={{ marginBottom: "10px" }}
                          >
                            <Input />
                          </Form.Item>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            type="primary"
                            danger
                            size="small"
                            onClick={() => handleDeleteImplicados(index)}
                          >
                            Borrar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" style={{ marginBottom: "10px" }}>
                Testigos
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#ccc", // Cambia este valor al tono de gris deseado
                            color: "white", // Cambia el color del texto si es necesario
                            borderColor: "#ccc", // Cambia el color del borde si es necesario
                          }}
                          size="small"
                          onClick={handleAddTestigos}
                        >
                          +
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Id_testigo.map((bahia, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Form.Item
                            label="Nombre y Apellidos"
                            initialValue={bahia.Nom_testigo}
                            onChange={(e) =>
                              handleTestigosChange(index, e.target.value)
                            }
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingrese Nombre y apellidos",
                              },
                            ]}
                            style={{ marginBottom: "10px" }}
                          >
                            <Input />
                          </Form.Item>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            type="primary"
                            danger
                            size="small"
                            onClick={() => handleDeleteTestigos(index)}
                          >
                            Borrar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <Form.Item
              label="ANEXOS (adjuntar documentos PDF, Word)"
              name="Anexos"
              style={{ marginBottom: "20px" }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {previewImage ? (
                  <p>{anexoFile.name}</p>
                ) : (
                  <p>Haz clic o arrastra el archivo para adjuntar</p>
                )}
                <Button icon={<InboxOutlined />} type="primary" danger>
                  Seleccionar archivo
                </Button>
              </div>
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
        <ReCAPTCHA
          sitekey="6Le3RnsiAAAAAA3yF7tEaf1JSxi54OUB819tq_OL"
          onChange={handleRecaptcha}
        />
      </div>
      <br/>
      <Form.Item style={{ textAlign: "right" }}>
        <Button
          type="primary"
          danger
          htmlType="submit"
          disabled={!captchaResult || loading}
        >
          Enviar Denuncia
        </Button>
      </Form.Item>
          </Form>

          <p>
            En cumplimiento de la Ley 1581 de 2012 sobre la que se expide el
            Régimen General de Protección de Datos Personales, el Decreto 1377
            de 2013 demás normas que modifiquen, extingan y adicionen en el que
            se regula expresamente la autorización del Titular de información
            para el tratamiento de los datos personales; SYZ Colombia S.A.S.
            como encargada y/o responsable del tratamiento de datos personales
            de sus trabajadores, clientes, usuarios finales y/o proveedores y
            contratistas con los que tenga o haya tenido relación, implementa,
            cumple y da a conocer públicamente los lineamientos establecidos
            bajo la Política de Tratamiento de Datos.
          </p>
        </div>
      )}
    </>
  );
};
