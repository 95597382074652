import { Breadcrumb, Layout, theme } from 'antd';
import React from 'react';
import { DenunciaForm } from "./Pages/Denuncias";
import LogoSyz from "./logo_syz.png";
import { Logo, LogoContainer } from "./styles";
const { Header, Content, Footer } = Layout;


const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const menuColor = "#08131A";
  return (
    <Layout>
      
      <Header
  style={{
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', // Alinea los elementos a los lados
    alignItems: 'center',
    background: menuColor,
    padding: '0 20px', // Añade espaciado interno
  }}
>
  <div className="demo-logo">
    <LogoContainer>
      <Logo src={LogoSyz} alt="Descripción del logo" />
    </LogoContainer>
  </div>
  
  <div style={{ color: 'white' }}><h2>Línea Ética</h2></div> {/* Título a la derecha */}
</Header>
      <Content
        className="site-layout"
        style={{
          padding: '0 50px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Denuncias</Breadcrumb.Item>
          
        </Breadcrumb>
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
          }}
        >
           <DenunciaForm />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        SYZ COLOMBIA ©2023
      </Footer>
    </Layout>
  );
};
export default App;